import { PlayerDecorator } from '@sscale/syncsdk';
import { isSafariOriOS } from '../../helper/device';

export default class YoutubePlayerDecorator extends PlayerDecorator {
  getPrecisionThreshold() {
    // Improve experience with stuttering issue on iOS and Safari
    if (isSafariOriOS()) {
      return 350;
    }

    return 100;
  }

  getSeekThreshold() {
    return 10000;
  }

  isSeekable() {
    return true;
  }

  isStalled() {
    try {
      return this.player.getPlayerState() === 3;
    } catch (e) {
      console.error('Is stalled error:', e);
    }

    return false;
  }

  play() {
    try {
      this.player.playVideo();
    } catch (e) {
      console.error('Play error:', e);
    }
  }

  pause() {
    try {
      this.player.pauseVideo();
    } catch (e) {
      console.error('Pause error:', e);
    }
  }

  getCurrentPosition() {
    try {
      return Math.round(this.player.getCurrentTime() * 1000);
    } catch (e) {
      console.error('Get current position error:', e);
    }

    return 0;
  }

  getPlaybackRate() {
    try {
      return this.player.getPlaybackRate();
    } catch (e) {
      console.error('Get playback rate error:', e);
    }

    return 0;
  }

  fastSeekToPosition(position) {
    try {
      window.sdkSeek = true;
      this.player.seekTo(position / 1000, true);
    } catch (e) {
      console.error('Fast seek error:', e);
    }
  }

  isPlaying() {
    try {
      return this.player.getPlayerState() === 1 || this.player.getPlayerState() === 3;
    } catch (e) {
      console.error('Is playing error:', e);
    }

    return false;
  }

  mute() {
    try {
      this.player.mute();
    } catch (e) {
      console.error('Mute error:', e);
    }
  }

  unmute() {
    try {
      this.player.unmute();
    } catch (e) {
      console.error('Unmute error:', e);
    }
  }

  changePlaybackRate(rate) {
    try {
      if (rate > 1) {
        rate += 0.05;
      } else if (rate < 1) {
        rate -= 0.05;
      }

      // Improve experience with stuttering issue on iOS and Safari
      if (isSafariOriOS()) {
        if (rate > 1) {
          rate = Math.ceil(rate * 10) / 10 + 0.3;
        }

        if (Math.ceil(rate * 100) / 100 === Math.ceil(this.getPlaybackRate() * 100) / 100) {
          return;
        }
      }

      this.player.setPlaybackRate(rate);
    } catch (e) {
      console.error('Set playback rate error:', e);
    }
  }

  setVolume(volume) {
    try {
      this.player.setVolume(volume * 100);
    } catch (e) {
      console.error('Set volume error:', e);
    }
  }
}
