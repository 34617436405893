import React, { useEffect, useRef, useCallback } from 'react';
import Hls from 'hls.js';
import YTLoader from 'youtube-iframe';
import './Player.css';

import {
  setClientToSdk,
  groupSeek,
  stopSync,
  startSynchronize,
  setGroup,
  attachDeltaListener,
  attachPlaybackRateListener,
} from 'services/SyncService';
import { useSelector } from 'react-redux';
import YoutubePlayerDecorator from 'services/SyncService/YoutubePlayerDecorator';
import { off, on } from '../../services/PubSub';

// const STREAM = 'https://vn-public-krk-dmz.demo.synamedia.co.uk/content/hlsmp4/low-latency-rugby-hls/master.m3u8';
const STREAM = 'https://demo-app.sceenic.co/football.m3u8';

const isSubArrayEnd = (A, B) => {
  if (A.length < B.length) return false;
  let i = 0;
  while (i < B.length) {
    if (A[A.length - i - 1] !== B[B.length - i - 1]) return false;
    i++;
  }
  return true;
};

let sequence = [];
let timer = null;

const Player = ({ isLoggedIn }) => {
  const { syncToken } = useSelector((state) => state.celebrity);
  const { participants } = useSelector((state) => state.session);
  const { roomId } = useSelector((state) => state.participant);

  const cleanup = useRef(() => {});

  useEffect(() => {
    if (!syncToken) {
      return;
    }

    async function sync() {
      await setGroup(syncToken, 'Sceenic');
      await startSynchronize();
    }

    sync();

    return () => {
      stopSync();
    };
  }, [syncToken]);

  useEffect(() => {
    attachDeltaListener((delta) => {
      const $el = document.getElementById('delta-info');
      if ($el) {
        $el.innerText = `${delta}ms`;
      }
    });

    // attachPlaybackRateListener((rate) => {
    //   const $el = document.getElementById('rate-info');
    //   if ($el) {
    //     $el.innerText = Number(rate).toFixed(2);
    //   }
    // });
  }, []);

  const handleReady = useCallback((player) => {
    if (!player) {
      return;
    }

    console.log('player', player);
    window.player = player;

    // setTimeout(() => {
    //   player.playVideo();
    // }, 0);

    setClientToSdk(new YoutubePlayerDecorator(player));

    // player.setVolume(5);

    cleanup.current = () => {
      player.destroy();
    };
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const $el = document.createElement('div');
    $el.classList.add('player');
    $el.setAttribute('id', 'youtube-player');
    const $container = document.getElementById('youtube-player-container');
    $container.appendChild($el);

    sequence = [];

    YTLoader.load((YT) => {
      new YT.Player('youtube-player', {
        height: '100%',
        width: '100%',
        videoId: 'LwihxyJ4V20',
        events: {
          onReady: (result) => {
            handleReady(result.target);
          },
          onPlaybackRateChange: (e) => {
            const $el = document.getElementById('rate-info');
            if ($el && e.data) {
              $el.innerText = Number(e.data).toFixed(2);
            }
          },
          onStateChange: (e) => {
            const type = e.data;

            if (type === 1 && sequence[sequence.length - 1] === 3 && !sequence.includes(-1)) {
              sequence = [];

              if (window.sdkSeek) {
                window.sdkSeek = false;
              } else {
                groupSeek(Math.round(e.target.getCurrentTime() * 1000));
              }
            } else {
              clearTimeout(timer); // Cancel previous event
              if (type !== 3) {
                // If we're not buffering,
                timer = setTimeout(function () {
                  // Start timer
                  sequence = [];
                }, 250);
              }
            }

            sequence = [...sequence, type];
          },
        },
        playerVars: {
          autoplay: true,
          rel: 0,
          mute: true,
          showinfo: 0,
          ecver: 2,
          playsinline: 1,
          modestbranding: false,
        },
      });
    });

    return () => {
      cleanup.current();
    };
  }, []);

  useEffect(() => {
    const listener = (count) => {
      const $el = document.getElementById('local-viewers');
      if ($el) $el.innerText = count;
    };

    on('viewers_count', listener);

    return () => {
      off('viewers_count', listener);
    };
  }, []);

  useEffect(() => {
    const listener = (gestureData) => {
      const normalizedData = {};
      if (gestureData.gestures.length > 0) {
        gestureData.gestures.forEach((gesture, idx) => {
          normalizedData[gestureData.handednesses[idx][0].categoryName] = gesture[0].categoryName;
        });
      }

      const { Left = 'N/A', Right = 'N/A' } = normalizedData;
      const $leftEl = document.getElementById('left-hand');
      if ($leftEl) $leftEl.innerText = Left;
      const $rightEl = document.getElementById('right-hand');
      if ($rightEl) $rightEl.innerText = Right;
    };

    on('gesture', listener);

    return () => {
      off('gesture', listener);
    };
  }, []);

  useEffect(() => {
    const listener = (disable) => {
      if (disable) {
        const $rateEl = document.getElementById('rate-info');
        if ($rateEl) $rateEl.innerText = '';

        const $deltaEl = document.getElementById('delta-info');
        if ($deltaEl) $deltaEl.innerText = '';
      }
    };

    on('sync_control', listener);

    return () => {
      off('sync_control', listener);
    };
  }, []);

  const showSyncInfo = participants.length > 0;
  const showStats = Boolean(roomId);

  return (
    <div className="player-container" id="youtube-player-container">
      {showStats && (
        <div className="stats">
          <div>
            Viewers: <span id="local-viewers">N/A</span>
          </div>
          <div>
            Left Hand: <span id="left-hand">N/A</span>
          </div>
          <div>
            Right Hand: <span id="right-hand">N/A</span>
          </div>
          {showSyncInfo && (
            <>
              <div>
                Delta: <span id="delta-info"></span>
              </div>
              <div>
                Rate: <span id="rate-info"></span>
              </div>
            </>
          )}
        </div>
      )}

      {/*<div className="player" id="youtube-player" />*/}
    </div>
  );
};
export default Player;
