import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { publish } from 'services/PubSub';

import './SyncControl.css';
import { startSynchronize, stopSync } from '../../services/SyncService';

const SyncControl = () => {
  const [disabled, setDisabled] = useState(false);

  function handleClick() {
    const shouldDisable = !disabled;

    publish('sync_control', shouldDisable);

    if (shouldDisable) {
      stopSync();
    } else {
      startSynchronize();
    }

    setDisabled(shouldDisable);
  }

  // useEffect(() => {
  //   handleClick();
  // }, []);

  return (
    <button
      className={classNames('sync-control', { 'sync-control__disabled': disabled })}
      onClick={handleClick}
      title={disabled ? 'Enable sync' : 'Disable sync'}
    />
  );
};

export default SyncControl;
