import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { isDarkTheme } from './services/params';
import configureStore from './store';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAlL9nvQE4uCK8LayICwLyo8t034-p1Gsc',
  authDomain: 'sceenic-demo.firebaseapp.com',
  projectId: 'sceenic-demo',
  storageBucket: 'sceenic-demo.appspot.com',
  messagingSenderId: '829440067138',
  appId: '1:829440067138:web:dae99c303d40cf7e6a0fdb',
  measurementId: 'G-L6FY3JJPQF',
};

if (isDarkTheme()) {
  document.querySelector('html')?.setAttribute('data-theme', 'dark');
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
