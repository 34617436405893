import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {off, on, publish} from 'services/PubSub';

import './FaceControl.css';

const FaceControl = () => {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const listener = () => {
      setDisabled(true);
    };
    on('gesture_control', listener);
    return () => off('gesture_control', listener);
  }, []);

  function handleClick() {
    const shouldDisable = !disabled;

    publish('face_control', shouldDisable);

    setDisabled(shouldDisable);
  }

  return (
    <button
      className={classNames('face-control', { 'face-control__disabled': disabled })}
      onClick={handleClick}
      title={disabled ? 'Enable face recognition' : 'Disable face recognition'}
    />
  );
};

export default FaceControl;
