import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import './InviteButton.css';

const InviteButton = () => {
  const { roomId } = useSelector((state) => state.participant);
  const [copied, setCopied] = useState(false);

  async function handleClick() {
    const url = new URL(window.location.href);
    url.searchParams.set('room', roomId);
    navigator.clipboard.writeText(url.toString());

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <button className="button invite-button" onClick={handleClick}>
      {copied ? 'Link copied' : 'Invite Co-Watcher'}
    </button>
  );
};

export default InviteButton;
