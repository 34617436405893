import qs from 'query-string';

const URL_PARAMS = qs.parse(window.location.search);

export function isIframeMode() {
    return URL_PARAMS.type === 'iframe';
}

export function isDarkTheme() {
    const prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)");

    return (prefersDarkTheme.matches || URL_PARAMS.theme === 'dark') && URL_PARAMS.theme !== 'light';
}
